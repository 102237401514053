.testimonials-container {
  background-color: #0b1f3d;
  padding: 4% 0%;
}

.testimonials-container h2 {
  color: #fff;
  text-align: center;
  font-family: "Poppins";
}

.slider-card {
  width: 40%;
  display: grid;
  justify-content: center;
  background-color: #fff;
  border-radius: 16px;
  margin-top: 5%;
}

.slider-img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8%;
  margin-bottom: 8%;
}

.slider-image {
  height: 100px;
  width: 100px;
  border-radius: 100%;
}

.slider-heading {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 110%;
  text-align: center;
  margin-bottom: 0%;
  margin: 0% 4% 0% 4%;
}

.slider-para {
  font-family: "Poppins", sans-serif;
  font-size: 90%;
  text-align: center;
  padding: 4%;
}

.react-3d-carousel .slider-container .slider-content .slider-single .slider-single-content {
  box-shadow: none !important;
}

.react-3d-carousel {
  background-color: #0b1f3d !important;
}

.proactive {
  margin-left: 5%;
}

.preactive {
  margin-left: -5%;
}

.slider-heading-avtar {
  /* border: 1px solid red; */
  padding: 2%;
  width: 60px;
  height: 60px;
  display: flex;
  font-size: 140%;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-family: "Poppins", sans-serif;
  background-color: #c6c6c6;
}

.react-3d-carousel {
  background: #001e22;
  /* height: 520px!important; */
  height: 380px !important;
}

.testimonials-heading-container {
  padding-left: 3%;
  padding-top: 3%;
}

.testimonials-heading {
  margin-bottom: 0%;
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  position: relative;
  z-index: 1;
}

.testimonials-heading-hr {
  border: 3px solid rgba(255, 212, 4, 1);
  width: 10%;
  margin-top: 0%;
  margin-bottom: 0%;
  position: relative;
  bottom: 10px;
  z-index: 0;
}

.react-3d-carousel .slider-container .slider-left {
  left: 43%;
  bottom: -53%;
  z-index: -1 !important;
}

.react-3d-carousel .slider-container .slider-right {
  left: 52% !important;
  z-index: -1 !important;
  bottom: -54%;
}

.react-3d-carousel .slider-container .slider-left div {
  top: 65% !important;
  border: none;
  border-radius: 50px;
  width: 48px;
  background-color: #f0a202;
  color: white !important;
  text-align: center;
  font-size: 110%;
}

.react-3d-carousel .slider-container .slider-left div:hover {
  /* color: #001e22 !important; */
  text-align: center;
  font-size: 110%;
}

.react-3d-carousel .slider-container .slider-right div {
  top: 64% !important;
  border-radius: 50px;
  border: none;
  width: 48px;
  background-color: #f0a202;
  color: white !important;
  text-align: center;
  font-size: 110%;
}

v .react-3d-carousel .slider-container .slider-right div:hover {
  color: #001e22 !important;
  text-align: center;
  font-size: 110%;
}

.slider-image {
  width: 80px;
  height: 80px;
}

@media (max-width: 420px) {
  .testimonials-container h2 {
    font-size: 140%;
  }
}

@media screen and (max-width: 767px) {
  .react-3d-carousel .slider-container .slider-left {
    left: -24%;
    z-index: -1 !important;
  }

  .react-3d-carousel .slider-container .slider-right {
    left: 106% !important;
    z-index: -1 !important;
  }

  .slider-card {
    width: 500px;
    display: grid;
    justify-content: center;
    background-color: #fff;
    border-radius: 16px;
    margin-top: 6%;
  }

  .proactive {
    margin-left: 10%;
  }

  .preactive {
    margin-left: -10%;
  }
.testimonials-container h2{
  margin: 10% 0%;
}
  .testimonials-heading-hr {
    width: 25%;
    bottom: 10px;
    z-index: 0;
  }
}