.footer-container {
  background-color: #142331;
  color: white;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 3% 8% 3% 12%;
}

.footer-col1 {
  width: 80%;
  margin-left: 10%;
}

.company-names-container {
  background-color: #142331;
  color: white;
  display: flex;
  justify-content: center;
}

.footer-col2 {
  padding-left: 25%;
}

.footer-headings {
  /* font-family: 'Poppins'; */
  display: flex;
  align-items: center;
  font-size: 120%;
  column-gap: 10px;
}

.footer-text {
  line-height: 2;
  /* font-family: 'Poppins'; */
}

.footer-col2-icon-wrapper {
  display: flex;
  column-gap: 5%;
}
.footer-headings-quick-link {
  font-size: 120%;
  margin-bottom: 2%;
}
.footer-col3 {
  /* padding-left: 20%; */
  text-align: center;
  /* background-color: red; */
}

.Footer-bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.footer-company-section {
  /* margin-left: 18%; */
  flex-basis: 50%;
}

.flex-bottom-container {
  flex-basis: 50%;
  margin-right: 21%;
}

.Footer-bottom-text {
  width: 100% !important;
  color: rgb(158, 155, 155);
}

.copyright-link {
  color: #fff;
}

.scrobits-link {
  color: #fff;
}

@media (max-width: 420px) {
  .footer-container {
    margin-top: 10%;
  }
}

@media screen and (max-width: 767px) {
  .footer-container {
    background-color: #142331;
    color: white;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    padding: 6% 0% 6% 0%;
  }

  .footer-col1 {
    width: 80%;
    margin-left: 10%;
  }

  .footer-col2 {
    margin-top: 9%;
    padding-left: 0%;
    width: 80%;
    margin-left: 10%;
  }

  .footer-col3 {
    margin-top: 9%;

    padding-left: 0%;
    width: 80%;
    margin-left: 10%;
  }

  .company-names-container {
    display: flex;
    flex-direction: column;
  }

  .footer-company-section {
    width: 80%;
    margin: auto;
    margin-top: 4%;
  }

  .flex-bottom-container {
    width: 80% !important;
    margin: auto;
    margin-bottom: 10%;
  }
}
