/* Career page CSS stats here  */
.career-container {
  width: 100%;
  text-align: center;
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 100%;
}

.career-page-career-section {
  /* background-image: url("../../../public/assets/roberto-sorin-ZZ3qxWFZNRg-unsplash\ 1\ \(1\).webp"); */
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5% 0% 1% 0%;
  height: 100vh;
}

.career-bg-img {
  width: 100%;
  position: absolute;
  color: white;
  z-index: -1;
}

.career-title-section {
  font-size: 28px;
  width: 75%;
  margin: auto;
}

.career-title-section h1 {
  margin: 1% 0%;
}

.career-description-contact {
  width: 50%;
  margin: auto;
  margin-top: 0%;
}

.career-description-contact p {
  /* margin: 2% 0%; */
  font-weight: 200;
}

.careers-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2%;
  position: relative;
}

.careers-title h1 {
  margin: 0%;
  font-weight: 600;
}

.careers-title svg {
  margin-right: 4%;
  margin-top: 2%;
  position: absolute;
}

.careers-middle-grid-container {
  width: 80%;
  margin-left: 10%;
  margin-top: -22%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  text-align: left;
  grid-gap: 5% 5%;
  margin-bottom: 10%;
}

.careers-middle-grid-item {
  padding: 5% 5% 10% 5%;
  /* border: 1px solid black; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 1px 1px 3px 2px #d4e0e49c;
  position: relative;
}

.careers-middle-grid-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #f0a202;
}

.careers-middle-grid-subTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #2e3e43;
}

.careers-middle-grid-para {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  margin-bottom: 20%;
}

.careers-button {
  color: #235789;
}

.careers-button:focus {
  border: none !important;
}

/* Career page CSS ends here  */
@media (max-width: 420px) {
  .career-bg-img {
    width: 100%;
    height: 100%;
  }

  .careers-middle-grid-container {
    display: grid;
    grid-template-columns: 100%;
  }

  .career-title-section {
    width: 90%;
    margin-left: 5%;
  }

  .career-title-section h1 {
    font-size: 80%;
  }

  .career-description-contact {
    width: 90%;
    margin-left: 5%;
  }

  .careers-middle-grid-container {
    margin-top: -60%;
    margin-bottom: 50% !important;
  }

  .careers-title h1 {
    font-size: 180%;
  }
}

@media (max-width: 820px) {
  .careers-middle-grid-container {
    margin-top: -100%;
    display: grid;
    grid-template-columns: 100%;
    margin-bottom: 20%;
  }

  .careers-button {
    width: 80%;
    margin: auto !important;
    padding: 2% !important;
  }

  .careers-title svg {
    margin-top: 5%;
  }
}