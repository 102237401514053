/* 404 Error Page CSS */

#notfound {
    position: relative;
    height: 100vh;
}

#notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.notfound {
    /* max-width: 92rem; */
    /* width: 100rem; */
    line-height: 1.4;
    text-align: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.notfound .notfound-404 {
    position: absolute;
    height: 100px;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: -1;
}

.notfound-btn {
    margin-top: 7%;
}

.notfound .notfound-404 h1 {
    font-family: "Poppins";

    color: #ececec;
    font-weight: bolder;
    font-size: 200px;
    margin: 0px;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.notfound-heading{
    font-weight: 700;
    font-size: 150%;
}
.notfound h2 {
    font-family: "Poppins";
    font-size: 25px;
    color: #000;
    font-weight: bolder;
    text-transform: uppercase;
    margin: 0px;
}

.notfound p {
    font-size: 16px;
    color: #000;
    font-weight: bolder;
    margin-top: 15px;
}

.notfound a {
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
    background: #189cf0;
    display: inline-block;
    padding: 16px 38px;
    border: 2px solid transparent;
    border-radius: 40px;
    color: #fff;
    font-weight: bolder;
    -webkit-transition: 0.2s all;
    transition: 0.2s all;
}

.notfound a:hover {
    background-color: #fff;
    border-color: #189cf0;
    color: #189cf0;
}

@media only screen and (max-width: 480px) {
    .notfound .notfound-404 h1 {
        font-size: 162px;
    }

    .notfound-heading {
        /* border: 1px solid red; */
        width: 80vw;
        margin: auto;
    }

    .notfound p {
        width: 90vw;
    }
}

@media screen and (max-width: 780px) {
    .notfound-heading {
        /* border: 1px solid red; */
        width: 80vw;
        margin: auto;
    }

    .notfound p {
        width: 90vw;
    }
}

/* --------------------------------- 404 Error Page Section  Ends
                        -------------------------------------------------------*/