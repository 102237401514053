/* aboutus page CSS stats here  */
.aboutus-container {
  width: 100%;
  text-align: center;
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 100%;
}

.aboutus-page-aboutus-section {
  background-image: url("../../public/assets/Group\ 3333.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5% 0% 1% 0%;
  height: 75vh;
}

.aboutus-title-section {
  font-size: 100%;
  width: 75%;
  margin: auto;
}

.aboutus-title-section h1 {
  margin: 2% 0%;
  font-weight: 800;
  font-size: 300%;
}

.aboutus-description-contact {
  width: 50%;
  margin: auto;
}

.aboutus-description-contact p {
  margin: 2% 0%;
  font-weight: 200;
}

.aboutus-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2%;
  position: relative;
}

.about-us-carousel {
  width: 70%;
  margin: auto;
}

.about-us-carousel img {
  width: 100%;
  /* height: 55vh; */
}

.aboutus-title h1 {
  margin: 0%;
  font-size: 200%;
}

.aboutus-title svg {
  margin-right: 4%;
  margin-top: 1.5%;
  position: absolute;
}

.aboutus-middle-grid-container {
  /* border: 1px solid red; */
  width: 90%;
  margin-left: 5%;
  margin-top: -12%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: space-between;
  text-align: center;
  grid-gap: 5% 10%;
  margin-bottom: 4%;
}

.aboutus-middle-hidden-container {
  background-color: #f0a202;
  box-shadow: 1px 1px 3px 2px #d4e0e49c;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.aboutus-middle-grid-item {
  padding: 5% 10% 10% 10%;
  height: 100%;
  /* border: 1px solid black; */
  border-radius: 8px;
  border-bottom-right-radius: 50px;
  background-color: #fff;
  position: relative;
}

.aboutus-middle-grid-item hr {
  border: 2px solid #f0a202;
  width: 20%;
  margin-left: 40%;
  background-color: #f0a202;
}

.aboutus-middle-grid-subTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  color: #2e3e43;
}

.aboutus-middle-grid-para {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  margin-bottom: 5%;
  margin-top: 15%;
}

.aboutsus-bottom-container {
  color: black;
  margin: auto;
  text-align: left;
  width: 50%;
}

.aboutsus-bottom-container h3 {
  font-size: 120%;
  font-weight: 600;
}

.aboutus-middle-para {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 100%;
  line-height: 27px;
  margin-bottom: 3%;
}

.aboutus-history-container {
  margin: 2% 0%;
}

.aboutus-history-container h3 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  color: #2e3e43;
  margin-bottom: 2%;
}

.aboutus-history-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 90%;
  margin: auto;
  margin-bottom: 5%;
}

.about-us-history-subheading-container {
  color: #000000;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 130%;
  text-align: left;
  display: flex;
  margin-top: 5%;
  /* justify-content: center; */
}

.about-us-history-subheading-container hr {
  background-color: #000000;
  border: 1px solid #000;
  width: 32%;
  margin-left: 1%;
  text-align: center;
  margin-top: 2.5%;
  margin-left: 2%;
}

.aboutus-history-left-section {
  width: 80%;
  margin: auto;
}

.aboutus-history-left-section p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 100%;
  margin-bottom: 3%;
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  color: #888888;
}

.aboutus-history-image-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.aboutus-history-right-section {
  display: grid;
  align-items: center;
  justify-content: center;
  /* border: 1px solid red; */
}

.aboutus-history-right-section img {
  width: 100%;
  /* box-shadow: 2rem 2rem #dbe6f0, -2rem -2rem #dbe6f0; */
}

.aboutus-image-background1 {
  width: 180px;
  height: 160px;
  /* background: rgba(35, 87, 137, 0.2); */
}

.aboutus-image-background2 {
  width: 180px;
  height: 160px;
  /* background: rgba(35, 87, 137, 0.2); */
  margin-left: 70%;
}

.aboutus-history-image-container {
  /* margin-top: -17.5%; */
  margin-left: 10%;
  margin-right: 10%;
  /* margin-bottom: -17.5%; */
}

.aboutus-history-image-container img {
  width: 100%;
}

.aboutus-blue-history-grid {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  margin-bottom: 5%;
  background: linear-gradient(90deg, #0b063d 47%, rgba(11, 6, 61, 0) 125.33%),
    url("../../public/assets/IMG_5820.webp");
  background-repeat: no-repeat;
  background-size: cover;
  /* background: linear-gradient(90deg, #0b063d 47%, rgba(11, 6, 61, 0) 125.33%),
    url("../assets/Images/IMG_5820.webp"); */
  transform: matrix(1, 0, 0, 1, 0, 0);
  padding: 9%;
}

.aboutus-blue-history-grid p {
  color: #fff !important;
}

.aboutus-blue-history-left-section {
  width: 80%;
  margin: auto;
}

.about-us-blue-history-subheading-container {
  color: #fff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 130%;
  text-align: left;
  display: flex;
  margin-bottom: 3%;
}

.about-us-blue-history-subheading-container hr {
  background-color: #fff;
  border: 1px solid #fff;
  width: 40%;
  margin-left: 1%;
  margin-top: 2.5%;
  margin-left: 2%;
}

.aboutus-blue-history-left-section p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: lighter;
  font-size: 100%;
  margin-bottom: 3%;
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  color: #888888;
}

td,
th {
  border: none;
  text-align: left;
  padding: 5px 50px 5px 50px;
  font-weight: normal;
  color: #888888;
}

.aboutus-third-right-section {
  display: grid;
  align-items: center;
  justify-content: center;
  /* border: 1px solid red; */
}

.aboutus-third-right-section p {
  color: #888888;
  text-align: left;
  font-weight: normal;
}

.aboutus-third-left-section p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 100%;
  margin-bottom: 3%;
  text-align: left;
  font-size: 16px;
  line-height: 24px;
  color: #888888;
}

.aboutus-third-left-section {
  width: 80%;
  margin: auto;
}

.about-us-third-subheading-container hr {
  background-color: #000000;
  border: 1px solid #000;
  width: 32%;
  margin-left: 1%;
  margin-top: 2.5%;
  margin-left: 2%;
}

.about-us-third-subheading-container {
  color: #000000;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 130%;
  text-align: left;
  display: flex;
}

.aboutus-third-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 90%;
  margin: auto;
  margin-bottom: 5%;
}

ul {
  list-style-position: outside;
  list-style-type: disc;
}

.about-us-our-strengths-wrapper {
  background-color: #142331;
  padding: 6% 20% 6% 20%;
  justify-content: left;
  text-align: left;
}

.about-us-our-strengths-para p {
  font-weight: normal;
  line-height: 28px;
  font-family: "Poppins";
}

.about-us-production-wrapper p {
  color: #888888;
  width: 80%;
  font-weight: normal;
  margin: auto;
  text-align: left;
  padding: 3% 10% 3% 10%;
}

/* aboutus page CSS ends here  */
@media (max-width: 420px) {
  .aboutus-middle-grid-container {
    display: grid;
    grid-template-columns: 100%;
    margin-bottom: 20% !important;
  }

  .aboutus-title-section {
    width: 90%;
    margin-left: 5%;
  }

  .aboutus-title-section h1 {
    font-size: 120%;
  }

  .aboutus-description-contact {
    width: 90%;
    margin-left: 5%;
  }

  .aboutus-history-grid {
    display: grid;
    grid-template-columns: 100%;
  }

  .aboutus-history-image-container img {
    width: 90%;
    margin-left: 5%;
  }

  .aboutus-image-background1 {
    width: 100px;
    height: 100px;
    /* background: rgba(35, 87, 137, 0.2); */
  }

  .aboutus-image-background2 {
    width: 100px;
    height: 100px;
    /* background: rgba(35, 87, 137, 0.2); */
    margin-left: 70%;
  }

  .aboutus-history-image-container {
    margin-top: 18%;
    margin-left: 7%;
    margin-right: 7%;
    margin-bottom: 18%;
    /* border: 1px solid red; */
  }

  .aboutus-title h1 {
    margin-top: 25%;
  }
}

@media (max-width: 780px) {
  .aboutus-middle-grid-container {
    margin-top: -60%;
    display: grid;
    grid-template-columns: 100%;
    margin-bottom: 20%;
  }

  .aboutus-history-grid {
    display: grid;
    grid-template-columns: 100%;
  }

  .aboutus-history-right-section {
    margin-bottom: 20%;
    margin-top: 15%;
    margin-left: 0% !important;
  }

  .aboutus-history-image-container img {
    width: 100%;
    margin-left: 0% !important;
  }

  .aboutus-history-image-container {
    margin-top: -15%;
    margin-left: 9%;
    margin-right: 13%;
    margin-bottom: -15%;
  }

  .about-us-history-subheading {
    text-align: left;
    /* margin-left: 5% !important; */
  }

  .about-us-history-subheading-container span {
    /* border: 1px solid red; */
    font-size: 90%;
    margin-bottom: 5%;
  }

  .about-us-history-subheading-container hr {
    display: none;
  }

  .aboutus-blue-history-grid {
    background: linear-gradient(0deg,
        #0b063d 41.59%,
        rgba(11, 6, 61, 0) 104.94%),
      url("../../public/assets/IMG_5820.webp");
    transform: matrix(1, 0, 0, 1, 0, 0);
    display: grid;
    grid-template-columns: 100%;
  }

  .about-us-blue-history-subheading-container {
    width: 80%;
    margin: 50% auto 5% 0%;
  }

  .about-us-blue-history-subheading-container hr {
    display: none;
  }

  .aboutus-blue-history-left-section p {
    width: 100%;
  }

  .aboutus-third-grid {
    display: grid;
    grid-template-columns: 100%;
    margin-top: 10%;
  }

  .about-us-third-subheading-container span {
    font-size: 80%;
  }

  .about-us-third-subheading-container hr {
    display: none;
  }

  .aboutus-history-grid {
    width: 100%;
  }

  .aboutus-third-right-section p {
    margin-bottom: 5%;
    /* border: 1px solid red; */
  }

  .about-us-our-strengths-wrapper {
    width: 100%;
    padding: 6% 5% 6% 6%;
    margin-bottom: 10%;
  }

  .about-us-production-wrapper p {
    width: 90%;
    margin: auto;
    /* border: 1px solid red; */
    padding: 0% 0% 0% 0% !important;
  }

  .about-us-first-table-wrappeer {
    /* border: 1px solid red; */
    margin: 10% 0%;
  }

  td,
  th {
    padding: 0%;
  }

  .aboutus-page-aboutus-section {
    background-image: url("../../public/assets/Group\ 3333.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 5% 0% 1% 0%;
    height: 75vh;
  }

  .about-us-blue-history-subheading-container {
    margin: 5%;
  }

  .aboutus-third-left-section {
    /* border: 1px solid red; */
    width: 100%;
    /* margin: auto; */
  }

  .aboutus-title svg {
    margin-top: 30%;

  }
}