.gallery-container {
  line-height: 0;
  -webkit-column-count: 4;
  -webkit-column-gap: 0px;
  -moz-column-count: 4;
  -moz-column-gap: 5px;
  -moz-row-gap: 5px;
  column-count: 4;
  column-gap: 5px;
  row-gap: 15px !important;
  display: inline-block;
  margin-right: auto;
  margin-left: auto;
  width: 96%;
  margin-left: 2%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 2%;
  /* display: grid;
  gap: 5px 10px;
  grid-template-columns: repeat(3, 1fr); */
}
.gallery-img-card img {
  cursor: pointer;
}
.image-gallery-container {
  /* height: 300px; */
  width: 100%;
  /* background-image: url("../../assets/Images/Products\ page\ main\ heading\ image.webp"); */
}
.gallery-img-card img {
  /* width: 100%; */
  width: 100% !important;
  height: auto !important;
}
@media (max-width: 1000px) {
  .gallery-container {
    -moz-column-count: 3;
    -webkit-column-count: 3;
    column-count: 3;
  }
}
@media (max-width: 800px) {
  .gallery-container {
    -moz-column-count: 2;
    -webkit-column-count: 2;
    column-count: 2;
  }
}
@media (max-width: 400px) {
  .gallery-container {
    -moz-column-count: 1;
    -webkit-column-count: 1;
    column-count: 1;
  }
}
