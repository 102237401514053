.navbar-brand {
  font-weight: 700;
  font-family: "Bauhaus 93";
  color: #235789 !important;
  flex-basis: 10%;
  width: 100%;
}

.navbar-brand img {
  width: 100%;
}

.navbar-logo-img {
  width: 15%;
  cursor: pointer;
}

.navbar-link-wrapper {
  display: flex;
  justify-content: flex-end;
  column-gap: 30px;
  width: 100%;
  align-items: center;
  padding: 0% 3% 0% 3%;
}

.navbar-links {
  text-decoration: none !important;
  font-size: 115%;
  font-family: "Poppins";
  color: #626262;
}

.navbar-links-selected {
  font-weight: bolder;
  color: #235789;
}

.navbar-collapse {
  visibility: visible !important;
}

.collapse {
  background-color: #fff;
  color: #235789;
  visibility: visible;
}

@media (max-width: 600px) {
  .navbar-logo-img {
    width: 40%;
  }
}

@media (max-width: 994px) {
  .navbar-main {
    padding: 0px !important;
  }

  .navbar-nav {
    background-color: #235789;
    color: #fff;
  }

  .collapse {
    visibility: visible !important;
    background-color: #fff;
    color: #000;
  }

  .navbar-brand {
    margin: 0% 2%;
  }

  .navbar-hamburger {
    margin: 0% 2%;
  }

  .navbar-content-container {
    padding: 5% 2%;
    background-color: #235789;
  }

  .navbar-link-wrapper {
    align-items: flex-end;
  }

  .nav-list-item {
    width: 100% !important;
    margin: 1% 0%;
  }

  .navbar-links {
    color: white;
  }

  .navbar-links-selected {
    font-weight: bolder;
  }
}