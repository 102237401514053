* {
  margin: 0;
  padding: 0;
}

.image-gallery-container {
  width: 90%;
  margin-left: 5%;
}

.image-gallery-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin: 2% 0%;
}

.image-gallery-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3% 0% 0% 0%;
  margin-bottom: 5%;
}

.image-gallery-title h1 {
  margin: 0%;
  font-weight: 600;
}

.image-gallery-title svg {
  margin-right: 16%;
  margin-top: -2.5%;
}

.image-gallery-inner-wrapper {
  margin: 3%;
  display: flex;
  justify-content: center;
}

.image-gallery-inner-wrapper:hover .image-gallery-images {
  background: rgba(39, 62, 84, 0.82);
}

.gallery-overlay {
  background: rgba(39, 62, 84, 0.82);
  overflow: hidden;
  height: 100%;
  z-index: 2;
}

.gallery-image-caption {
  position: absolute;
  justify-content: center;
  margin-top: 11%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 100%;
  line-height: 17px;
  color: #fdfffc;
  visibility: hidden;
}

.image-gallery-inner-wrapper:hover .gallery-image-caption {
  visibility: visible;
}

.image-gallery-images {
  width: 100%;
  height: 200px;
  /* border: 1px solid grey; */
  cursor: pointer;
  /* object-fit: cover; */
  /* padding: 3%; */
}

.image-gallery-images:hover {
  visibility: visible;
  filter: brightness(70%);
  /* opacity: 1; */
}

/* FULL SCREEN CSS */
.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.945);
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
}

.model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.model img {
  width: 100%;
  max-width: 100%;
  height: auto;
  height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 4% 5% 4% 5%;
  margin: 0% auto;
}

.image-gallery-button {
  color: rgb(235, 235, 238);
  position: absolute;
  right: 1rem;
  padding: 0.4%;
  /* background-color: transparent; */
  border: none;
  top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* MEDIA QUERIES */
@media (max-width: 480px) {
  .image-gallery-title h1 {
    font-size: 180%;
    margin-top: 8%;
  }
}

@media screen and (max-width: 767px) {
  .image-gallery-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .gallery-image-caption {
    margin-top: 45%;
  }

  .model img {
    width: 100%;
    max-width: 100%;
    height: auto;
    height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 4% 5% 4% 5%;
    margin: 0% auto;
  }

  .model img {
    width: 100%;
    max-width: 100%;
    height: 40vh;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 4% 5% 4% 5%;
    margin: 0% auto;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .image-gallery-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}