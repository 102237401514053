@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

* {
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
}

.contact-us-main {
  background-color: white;
  width: 80%;
  margin: 0% auto;
}

.contact-us-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2% 0% 0% 0%;

}

.contact-us-title h1 {
  margin: 3% 0% 0% 0%;
  font-weight: 600;
  font-size: 180%;
  font-family: "Poppins";
}

.contact-us-title svg {
  margin-right: 4%;
  margin-top: -1%;
}

.contact-form-wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  margin-top: 2%;
  background-color: white;
  padding: 0% 8% 6% 8%;
  align-items: center;
}

/* CONTACT US LEFT SECTION */
.contact-us-left-section-form {
  width: 100%;
}

.contact-us-submit-btn {
  border: none;
  width: 40%;
  padding: 1% 0% 1% 0%;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 110%;
  cursor: pointer;
  background-color: #F0A202 !important;
}

.contact-us-left-section-form-inputs {
  width: 80%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid grey;
  padding: 1% 2% 0.5% 2%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 95%;
  color: #575656;
  margin-bottom: 7%;
  outline: none;

}

.contact-us-left-section-form-inputs-1 {
  width: 80%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid grey;
  padding: 1% 2% 0.5% 2%;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 95%;
  color: #575656;
  margin-bottom: 0.3%;
  outline: none;

}

.contact-us-message {
  color: #575656;
  font-family: "Poppins", sans-serif;
  font-size: 80%;
  padding: 0% 0% 0% 2%;
}

.contact-us-left-section-form-input-container {
  display: grid;
  margin-bottom: 7%;
}


.contact-us-right-section-row1 {
  display: flex;
  align-items: center;
  margin-bottom: 2%;
}

.contact-us-right-section-para {
  margin-bottom: 0%;
  margin-left: 2%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 95%;
  line-height: 120%;
  color: #575656;
  cursor: pointer;

}

.contact-us-right-section-first-row {
  margin-bottom: 6%;
}

.contact-us-right-section-subheading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 130%;
  line-height: 120%;
  color: #575656;
  margin-bottom: 4%;
}

.contact-us-right-section-row1 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 110%;
  line-height: 120%;
  color: #575656;

}

.contact-us-right-section-row2 {
  margin-top: 4%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 120%;
  color: #575656;
}

.contact-fontawesome-style {
  font-size: 105%;
}

.contact-us-right-section {
  margin: 2% 0%;
}

.contact-us-right-section-media-container {
  display: flex;
  align-items: center;
  margin-top: 2%;
}

.contact-social-media-icon {
  font-size: 180%;
  margin-right: 4%;
  color: #575656;
}

.contact-us-left-section-form-para {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: #575656;
  margin-top: -5%;
  justify-content: left;
  width: 50%;
  margin-left: 2%;
  justify-content: left;
  text-align: left;
}

/* MEDIA QUERIES */

@media (max-width: 420px) {
  .contact-form-wrapper {
    margin-bottom: 5%;
    margin-top: 15% !important;
    /* border: 5px solid red; */
  }
}

@media screen and (max-width: 767px) {
  .contact-us-main {
    width: 90%;
    margin: 10% auto;
    padding: 0% !important;
  }

  .contact-form-wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 90%;
    margin-left: 5%;
    margin-top: 5%;
    padding: 0%;
  }

  .contact-us-right-section-subheading {
    color: #575656;
    margin-top: 1%;
  }

  .contact-us-right-section-para {
    margin-bottom: 0%;
    margin-left: 2%;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 85%;
    line-height: 120%;
    color: #575656;
  }

  .contactus-form-heading {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 180%;
    position: relative;
    z-index: 1;
    margin-top: 4%;
  }

  .contact-form-heading-hr-tag {
    border: 3px solid #ffd404;
    margin: 0%;
    width: 30%;
    position: absolute;
    bottom: 5%;
  }


  .contact-us-left-section-form-inputs {
    width: 100%;
  }

  .contact-us-left-section-form-inputs-1 {
    width: 100%;
  }

  .contact-us-submit-btn {
    border: none;
    width: 100%;
    margin-top: 3%;
    margin-bottom: 10%;
  }


  .contact-us-right-section-first-row {

    margin-bottom: 5%;
  }

}