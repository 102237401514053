* {
  font-family: "Poppins" !important;
}

.homepage-last-cta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 2% auto;
  padding: 1% 1%;
  border-radius: 30px;
  overflow: hidden;
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.3);
  padding: 2%;
  color: white;
  background-image: linear-gradient(-20deg, #142331, #235789);
}

.homepage-last-cta h2 {
  font-size: larger;
}

.homepage-last-cta a {
  width: 50%;
  display: flex;
  justify-content: right;
  margin: auto;
}

.homepage-last-cta button {
  border: none;
  background-color: #f0a202;
  color: white;
  padding: 1% 5%;
  border-radius: 5px;
  width: 30%;
}

.products-page-container {
  /* background: linear-gradient(91.74deg, #54b4d2 -1.35%, #293f75 102.83%); */
  background-image: url("../../public/assets/Products\ page\ main\ heading\ image.webp");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2% 0% 1% 0%;
  width: 100%;
  height: 100%;
  /* height: 60vh; */
  color: white;
  /* padding: 0% 0% 1% 0%; */
}

/* cards css */

.scrobits__services-container {
  -webkit-transition: all 0.5s 0s ease-out;
  -moz-transition: all 0.5s 0s ease-out;
  -o-transition: all 0.5s 0s ease-out;
  transition: all 0.5s 0s ease-out;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 3rem;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.scrobits__service-container {
  width: 250px;
  height: 325px;

  background: linear-gradient(144.38deg,
      rgba(27, 120, 222, 0.4) 0%,
      rgba(4, 44, 84, 0.1) 104.36%);

  backdrop-filter: blur(60px);
  border-radius: 5% 5% 5% 5%;
  /* box-shadow: inset 1px 1px 2px white; */

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  text-align: center;
}

/* .scrobits__service-container:hover {
 
} */
.scrobits__service-icon {
  width: 50px;
  height: 50px;
}

.scrobits__service-icon img {
  width: 100%;
  height: 100%;
}

.scrobits__service-title {
  margin-top: 2rem;
  margin-bottom: 3px;
}

.scrobits__service-description {
  margin-top: 1rem;
}

.products-page-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1% 0% 0% 0%;
}

.products-page-title h1 {
  margin: 0%;
  font-family: "Poppins";
}

.product-call-to-action-container {
  /* height: 20vh; */
  width: 100%;
  visibility: hidden;
  /* border: 1px solid red; */
}

.products-page-title svg {
  margin-right: 4%;
  margin-top: -1%;
}

.product-page-categories {
  margin: 1% 0%;
  display: flex;
  flex-direction: column;
}

.categories-title {
  text-align: center;
  margin: 2% 0%;
}

.category-select {
  display: flex;
  gap: 4%;
  justify-content: center;
}

.category-batteries {
  border: 1px solid white;
  flex-basis: 20%;
  border-radius: 10px;
  cursor: pointer;
}

.category-batteries:hover {
  background-color: white;
  color: black;
  border-color: white;
}

.category-batteries-selected {
  border: none;
  background-color: white;
  color: black;
}

.category-batteries-selected:hover {
  background-color: white;
  opacity: 1;
}

.category-batteries p {
  margin: 1% 5%;
}

.products-page-list {
  display: grid;
  padding: 2%;
  gap: 0;
  width: 90%;
  margin-left: 5%;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 5%;
}

.product-list-card {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  padding: 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 5%;
  margin-left: 5%;
}

/* .products-page-list {
  display: flex;
  justify-content: space-between;
  gap: 2%;
  flex-wrap: wrap;
  width: 100%;
}

.product-list-card {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin: 2% 0%;
} */

.product-list-card:hover .product-list-card {
  visibility: hidden !important;
}

.product-list-card:hover .product-call-to-action-container {
  visibility: visible;
}

.product-img {
  border-bottom: 1px solid rgb(183, 183, 183);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.product-img img {
  width: 350px;
  height: 300px;
}

.product-content {
  margin: 4% 0%;
  width: 85%;
  height: 100%;
}

.product-content h5 {
  margin-bottom: 8%;
}

.product-content p span {
  color: #2e3e43;
  font-weight: bolder;
}

/* card design */

@media (max-width: 480px) {
  .products-page-title h1 {
    margin-top: 5%;
    font-size: 180%;
  }

  .products-page-list {
    grid-template-columns: repeat(1, 1fr);
    gap: 0%;
  }

  .homepage-last-cta {
    flex-direction: column;
    justify-content: space-evenly;
  }

  .homepage-last-cta button {
    width: 90%;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .homepage-last-cta h2 {
    font-size: large;

    width: 95%;
  }

  .category-select {
    flex-direction: column;
  }
}

@media only screen and (max-width: 767px) {
  .category-batteries {
    margin: 2% auto;
    width: 75%;
  }

  .product-list-card {
    margin-left: 0%;
  }

  .category-select {
    flex-direction: column;
  }

  .homepage-last-cta {
    flex-direction: column;
    margin-top: 15%;
    width: 90%;
    margin-left: 5%;
    padding: 5%;
  }

  .homepage-last-cta button {
    width: 80%;
    margin-left: 0%;
    margin-top: 5%;
  }

  .products-page-title h1 {
    margin-top: 5%;
    font-size: 180%;
  }

  .products-page-list {
    grid-template-columns: repeat(1, 1fr);
    gap: 0%;
  }
}