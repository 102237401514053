.home-container {
  width: 100%;
  text-align: center;
  color: white;
  font-family: "Poppins", sans-serif;
  background-color: white;
}

/* Hero section CSS starts */

.hero-section-wrapper {
  position: relative;
  top: 0%;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0% 0%;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.home-page-hero-section {
  position: relative;
}

.hero-background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: cover;
  margin-top: 0%;
  height: 100%;
}

.hero-title-section {
  font-size: 28px;
  margin: 1% auto;
  margin-top: 8%;
}

.hero-title-section h1 {
  /* margin: 2% 0%; */
  font-weight: 600;
}

.hero-description-contact {
  width: 50%;
  margin: 0% auto;
}

.hero-description-contact p {
  font-size: 115%;
}

.hero-description-contact-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero-description-contact-btn a {
  padding: 1% 4%;
  outline: none;
  border: none;
  color: white;
  background-color: #f0a202;
  margin: 2% 0%;
  font-size: medium;
}
.hero-description-contact-btn a:hover {
  text-decoration: none;
}
.hero-feats {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3%;
  margin: 1% 0%;
  flex-wrap: wrap;
}

.hero-feats img {
  width: 100px;
}

.hero-feats-association p {
  margin: 1% 0%;
}

.hero-end-row {
  margin: 0% 4% 1% 4%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
}

.hero-end-circle-arrow {
  display: flex;
  align-items: center;
}

.hero-end-circle-arrow img:nth-child(1) {
  position: relative;
  left: 50%;
  right: 50%;
  bottom: 40%;
}

.hero-watch-video {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 15%;
  gap: 2%;
  flex-wrap: wrap;
  fill: white;
}

.hero-watch-video:hover {
  color: #235789 !important;
  fill: #235789 !important;
  cursor: pointer;
}

.hero-watch-video p {
  display: inline-block;
  margin: 1% 0%;
}

/* Hero section CSS ends */

/* Trusted By Section CSS starts */

.home-page-trusted-by-section {
  margin: 2% 0%;
  font-family: "Poppins", sans-serif;
}

.trusted-by-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2%;
}

.trusted-by-title h1 {
  margin: 0%;
  font-weight: 600;
  font-size: 180%;
}

.trusted-by-title svg {
  margin-right: 3%;
  margin-top: -1%;
}

.trusted-by-companies {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 1% 4%;
  /* scroll-behavior: auto; */
}

.trusted-by-companies img {
  margin: 2% 0%;
  width: 8%;
  /* height: 30%; */
}

/* Trusted By Section CSS ends */

/* Home Page machines css begins here */

.home-page-machines {
  display: flex;
  flex-wrap: wrap;
  padding: 2% 8%;
  justify-content: space-around;
  font-family: "Poppins", sans-serif;
}

.machine-card-wrapper1 {
  display: flex;
  flex-direction: column;
  flex-basis: 40%;
  /* margin-bottom: 10%;   */
}

.machine-card-wrapper2 {
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  flex-basis: 40%;
}

.machines-card {
  margin: 2% 0%;
}

.machines-card img {
  width: 100%;
}

.machine-card-content h2 {
  color: #235789;
  margin-top: 2%;
  font-size: 130%;
  font-weight: 600;
}

.machine-card-content p {
  color: #888888;
  margin-bottom: 10%;
}

.machine-card-media {
  position: relative;
}

#playVideo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.machine-card-media video {
  border-radius: 8px;
  width: 100%;
}

.our-facilities-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2%;
}

.our-facilities-title h1 {
  margin: 0%;
  font-weight: 600;
  font-size: 180%;
}

.our-facilities-title svg {
  margin-right: 4%;
  margin-top: -1%;
}

/* Home Page machines css ends here */

/* Custom Products CSS starts here */

.home-page-custom-products {
  margin: 1% 0%;
  font-family: "Poppins", sans-serif;
}

.custom-products-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2%;
  position: relative;
}

.custom-products-title h1 {
  margin: 0%;
  font-weight: 600;
  font-size: 180%;
}

.custom-products-title svg {
  margin-right: 38%;
  margin-top: 1.5%;
  position: absolute;
}

.custom-products-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 5%;
  width: 80%;
  margin: auto;
  margin-left: 6%;
  justify-items: center;
  margin-left: 10%;
}

.custom-product-card-p1 {
  color: #149911;
}

.custom-product-card-p2 {
  color: #ffa500;
}

.custom-product-card-p3 {
  color: #3d8ccc;
}

/* Custom Products CSS edns here */

/* Mag Power Video section starts*/

.home-page-video-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 11% 0%;
  background: url("../../public/assets/VideoSectionBackground.webp");
  background-size: cover;
  background-repeat: no-repeat;
}

.home-page-video-section h1 {
  margin: 1% 0%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  color: #235789;
}

.home-page-video-card {
  width: 60%;
}

.home-page-video-card video {
  border-radius: 8px;
  box-shadow: 0px 8px 20px 4px rgba(4, 100, 189, 0.5);
}

.home-page-video-card img {
  width: 100%;
  border-radius: 20px;
}

/* Mag Power Video section ends*/

/* Why Us CSS Starts here */

.home-page-why-us {
  font-family: "Poppins", sans-serif;
  margin: 0% 0% 5% 0%;
}

.why-us-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2%;
}

.why-us-title h1 {
  margin: 0%;
  font-weight: 600;
  font-size: 180%;
}

.why-us-title svg {
  margin-right: 2%;
  margin-top: -1%;
}

.why-us-cards-wrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.why-us-card {
  display: flex;
  flex-direction: column;
  flex-basis: 20%;
  box-shadow: 0px 0px 4px 1px rgba(4, 100, 189, 0.5);
  background: radial-gradient(
    100% 156.25% at 100% 100%,
    #062f57 0%,
    #0d6cc7 100%
  );
  justify-content: space-between;
}

.why-us-card-img img {
  width: 100%;
  height: 100%;
}

.why-us-content {
  color: white;
  /* margin-top: 5%; */
}

.why-us-content h4 {
  padding-left: 5%;
  /* padding: 0% 5%; */
  /* font-weight: 600; */
  font-size: 120%;
  width: 90%;
  margin-top: 5%;
}

.why-us-content p {
  padding: 0% 5%;
  font-size: 80%;
  width: 90%;
}

.why-us-read-more {
  padding: 0% 5%;
  margin: 1% 0%;
}

.why-us-corner {
  display: flex;
  justify-content: flex-end;
  background-color: inherit;
  position: static;
}

/* Why Us CSS ends here */

/* Machineries owned css starts here */

.home-page-machines-owned {
  margin: 2% 0%;
  display: flex;
  justify-content: space-between;
}

.machines-owned-content {
  flex-basis: 50%;
  display: flex;
  align-items: center;
}

.machines-owned-content-inner {
  width: 75%;
  padding: 6% 8%;
  margin: 0% auto;
  margin-bottom: 4%;
  background-color: #f0f6f9;
  box-shadow: -2rem 2rem #dbe6f0;
}

.machines-owned-content-inner h2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 120%;
  color: #235789;
}

.machines-owned-content-inner p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 90%;
  line-height: 120%;
  color: #888888;
  margin-top: 5%;
}

.machines-owned-content button {
  padding: 2% 8%;
  outline: none;
  border: none;
  color: white;
  background-color: #f0a202;
  margin: 2% 0%;
  font-size: medium;
}

.machines-owned-carousel {
  flex-basis: 50%;
  margin: 5% 0%;
}

.machines-owned-carousel img {
  width: 100% !important;
  height: 100%;
}

/* Machineries owned css ends here */

/* MagPower Gallery CSS starts here*/

.home-page-gallery {
  margin: 2% 0%;
}

.gallery-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2%;
}

.gallery-title h1 {
  margin: 0%;
  font-weight: 600;
  font-size: 180%;
}

.gallery-title svg {
  margin-right: 12%;
  margin-top: -1% !important;
}

.gallery-pictures-section {
  margin: 1% 0% 0% 0%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.gallery-img {
  flex-basis: 20%;
  margin: 2% 0%;
  position: relative;
}

.gallery-img img {
  width: 100%;
}

.gallery-img p {
  position: absolute;
  top: 82%;
  right: 2%;
  color: white;
}

.gallery-see-all {
  text-align: right;
  margin: 0% 3%;
  font-weight: bold;
  text-decoration: underline;
  font-family: "Poppins" sans-serif;
}

.gallery-section-wrapper {
  width: 90%;
  margin-left: 5%;
}

/* MagPower Gallery CSS ends here*/

/* Home Page Contact Us CSS starts */

.home-page-contact-us {
  background-image: url("../../public/assets/ContactBackground.webp");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 4%;
}

.page-link:disabled {
  cursor: not-allowed;
}

/* Home Page Contact Us CSS ends*/

@media (max-width: 420px) {
  .hero-title-section {
    width: 100%;
  }

  .gallery-title h1 {
    margin: 10% 0% 0% 0%;
    font-weight: 600;
    font-size: 180%;
  }

  .home-page-gallery {
    margin-bottom: 10%;
  }

  .hero-description-contact {
    width: 85%;
  }

  .hero-description-contact {
    margin: 3% auto;
  }

  .hero-feats {
    flex-direction: column;
  }

  .hero-feats-association {
    margin: 5% 0%;
  }

  .hero-feats img:last-child {
    margin-top: 2%;
  }

  .hero-end-row {
    flex-direction: column-reverse;
    justify-content: center;
  }

  .hero-end-circle-arrow {
    display: none;
  }

  .hero-watch-video {
    justify-content: center;
  }

  .machine-card-wrapper1 {
    flex-basis: 100%;
  }

  .machine-card-wrapper2 {
    flex-basis: 100%;
  }

  .hero-title-section h1 {
    margin: 10% 0%;
    font-size: 80%;
  }

  .hero-description-contact p {
    margin: 10% 0%;
    font-size: 80%;
  }

  .hero-description-contact button {
    width: 90%;
    margin-left: 5%;
  }

  .trusted-by-title h1 {
    margin: 10% 0% 0% 0%;
    font-size: 150%;
    font-weight: 600;
  }

  .machine-card-content h2 {
    margin: 10% 0% 1% 0%;
    font-size: 130%;
    word-break: normal;
  }

  .custom-products-title h1 {
    font-weight: 600;
    font-size: 130%;
  }

  .custom-products-list {
    width: 80%;
    display: grid;
    grid-template-columns: 100%;
  }

  .machines-owned-carousel {
    margin: 10% 0%;
  }

  .home-page-video-card {
    width: 80%;
    margin-top: 5%;
    /* margin-left: 5%; */
  }

  .our-facilities-title h1 {
    margin: 10% 0% 0% 0%;
    font-size: 150%;
    font-weight: 600;
  }

  .custom-products-title h1 {
    margin: 10% 0% 0% 0%;
    font-size: 150%;
    font-weight: 600;
  }

  /* video title adjusting */
  .home-page-video-section h1 {
    margin-top: 20%;
    margin-left: 5%;
    width: 90%;
  }
}

@media (max-width: 850px) {
  .trusted-by-companies {
    /* border: 1px solid red; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: auto;
    /* justify-content: center; */
    align-items: center;
    align-self: center;
    justify-content: space-around;
    margin-bottom: 10%;
  }

  .trusted-by-companies img {
    /* border: 1px solid green; */
    width: 60%;
    margin: auto;
  }

  .home-page-video-section h1 {
    margin-top: 30%;
    margin-bottom: 5%;
    margin-left: 5%;
    width: 80%;
    font-size: 120% !important;
    /* border: 1px solid red; */
  }

  .why-us-cards-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .why-us-card {
    margin: 5% 0%;
    width: 75%;
  }

  .home-page-machines-owned {
    flex-direction: column;
  }

  .machines-owned-content-inner button {
    width: 100%;
    padding: 2%;
  }

  .gallery-pictures-section {
    flex-direction: column;
  }

  .gallery-title svg {
    margin-right: 39%;
    margin-top: -0.5%;
  }

  .custom-product-card {
    width: 100% !important;
  }

  .custom-products-list {
    display: grid;
    grid-template-columns: 100%;
    margin: auto;
    margin-bottom: 20%;
  }

  .custom-products-list img {
    width: 90%;
    margin: auto;
    display: inline !important;
    margin-left: 5%;
  }

  .custom-products-title h1 {
    margin: auto;
    font-weight: 600;
    font-size: 150%;
    /* margin-left: 50% !important; */
    text-align: center;
  }

  .custom-products-title svg {
    margin-right: 10%;
    margin-top: -1%;
  }

  .our-facilities-title h1 {
    margin-top: 5%;
  }

  .custom-products-title h1 {
    margin-top: 5%;
  }

  .home-page-machines {
    display: grid;
    grid-template-columns: 100%;
    margin-bottom: 5%;
  }

  .machine-card-content h2 {
    /* border: 1px solid red; */
    margin-top: 0%;
  }

  .machine-card-wrapper1 {
    margin-bottom: 0%;
  }

  .machine-card-wrapper2 {
    margin-top: 0%;
  }

  .home-page-video-card {
    width: 90%;
    margin: auto;
    margin-top: 3%;
  }

  .hero-section-wrapper {
    justify-content: space-between;
  }

  .hero-description-contact-btn button {
    width: 90%;
    padding: 3%;
    margin: 0% 0% 10% 0%;
  }

  .hero-description-contact p {
    margin-bottom: 10% !important;
    /* border: 1px solid red; */
  }
}

/* Hero Section Responsiveness Media Queries */
@media (max-width: 400px) {
  .hero-title-section {
    margin: 20% 0% 1% 0%;
  }

  .hero-title-section h1 {
    margin: 1% 0%;
  }

  .hero-description-contact {
    margin: 1% auto;
  }

  .hero-description-contact p {
    margin: 1% 0%;
  }

  .hero-feats {
    flex-direction: row;
    justify-content: space-around;
  }

  .hero-feats img {
    /* flex-basis: 40%; */
    /* border: 1px solid red; */
    width: 120px;
  }

  .hero-feats img:nth-child(3) {
    margin-top: 5%;
  }

  .hero-feats img:nth-child(4) {
    margin-top: 5%;
  }
}
